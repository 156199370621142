@import 'reset';
@import 'variables';
@import 'toastify';
@import 'shared';

html,
input,
button,
textarea {
  font-family: Lato, sans-serif;
}

* {
  box-sizing: border-box;
}

input {
  &::placeholder {
    color: rgba(46, 56, 77, 0.32);
  }

  &:hover:not(:disabled) {
    border-color: #8798ad;
  }

  &:focus {
    border-color: #69707f;
  }

  &:disabled {
    background-color: var(--color-bg-disabled);
  }
}

textarea {
  &::placeholder {
    color: rgba(46, 56, 77, 0.32);
  }

  &:hover {
    border-color: #8798ad;
  }

  &:focus {
    border-color: #69707f;
  }
}

:link {
  text-decoration: none;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.zero-margin {
  margin: 0;
}

.mgrpdf-navigation {
  position: sticky;
  bottom: 0;
}

#root {
  min-width: 1024px;
}
