@import 'src/styles/shared';

.header {
  display: flex;
  padding: 40px 34px 50px 34px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #f31c02;

  @include aes {
    background: linear-gradient(180deg, #f3f6f9, #fff);
  }

  @include gandh {
    background: var(--color-white);
  }
}

.main {
  flex-grow: 1;
  padding: 0 34px 34px;
}

.btnNewUser {
  white-space: nowrap;
  margin-right: 20px;
}

.grid {
  max-height: calc(100vh - 192px);
  margin-top: -32px;
  flex-grow: 1;
  overflow: auto;
  @extend %scrollbar;

  :global(.grid-row) {
    display: grid;
    grid-template-columns: repeat(6, 1fr) 110px 200px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(#f2f2f2, 0.5);
    }
  }
}

.user {
  :global(.column) {
    padding: 12px 16px;
    box-shadow: 0 1px 0 0 #f3f6f9;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
