@import '../../styles/shared';

.calendar {
  --font-size-base: 10px;

  @include gandh {
    --font-size-base: 14px;
  }

  width: 100%;
  color: var(--light-title-color);

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:global(.react-calendar__month-view__days__day):disabled {
      opacity: 0.48;

      cursor: not-allowed;
    }
  }

  :global(.react-calendar__month-view) {
    font-size: calc(var(--font-size-base) * 1.2);
    line-height: calc(var(--font-size-base) * 1.4);
  }

  :global(.react-calendar__navigation) {
    padding: 0 4% calc(var(--font-size-base) * 0.8);
  }

  :global(.react-calendar__navigation__arrow) {
    background: transparent;
    cursor: pointer;

    font-size: calc(var(--font-size-base) * 2.4);
  }

  :global(.react-calendar__navigation__label) {
    background: transparent;

    font-size: calc(var(--font-size-base) * 1.7);
    line-height: calc(var(--font-size-base) * 1.7);
  }

  :global(.react-calendar__month-view__weekdays__weekday) {
    padding: var(--font-size-base) 0;

    text-align: center;
    font-size: calc(var(--font-size-base) * 1.4);
    line-height: calc(var(--font-size-base) * 1.4);

    abbr {
      text-decoration: none;
    }
  }

  :global(.react-calendar__month-view__days__day--neighboringMonth) {
    opacity: 0.24;
  }

  :global(.react-calendar__tile) {
    padding: calc(var(--font-size-base) * 2) 0;

    background: transparent;
    cursor: pointer;

    abbr {
      position: relative;

      @include gandh {
        font-size: calc(var(--font-size-base) * 1.4);
      }
    }
  }

  :global(.react-calendar__tile--now) {
    position: relative;

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;

      width: calc(var(--font-size-base) * 3.4);
      height: calc(var(--font-size-base) * 3.4);

      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: white;
    }
  }

  :global(.react-calendar-day--holiday) {
    color: var(--calendarDay-accent-textColor);
  }

  &__light {
    :global(.react-calendar__month-view__weekdays__weekday) {
      color: var(--light-text-color);
    }

    :global(.react-calendar__navigation__label) {
      color: var(--light-text-color);
    }

    :global(.react-calendar__navigation__arrow) {
      color: var(--light-icon-color);
    }

    :global(.react-calendar__tile--now) {
      color: var(--color-white);

      &:before {
        background-color: var(--calendarDay-accent-textColor);
      }
    }
  }
}
