@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    width: 560px;
    height: 680px;

    border-radius: 8px;
  }

  .rc-dialog-close {
    top: 12px;
    right: 18px;
    padding: 0;
  }
}

.title {
  padding: 18px 40px;
  color: #747b8b;
}

.wrapper__image {
  height: 315px;
}

.room__info {
  @extend %scrollbar;

  display: flex;
  flex-direction: column;
  height: 310px;
  padding: 40px;
  overflow: auto;

  color: #000000;
}

.info__line {
  display: grid;
  grid-template-columns: 25px 1fr 210px;
  margin-bottom: 24px;

  * > path {
    fill: #000 !important;
  }
}

.info__value {
  text-align: right;
}

.cost__value {
  line-height: 1.2;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  display: inline-block;
  margin-bottom: 8px;
  padding: 5px 16px;
  font-size: 14px;

  border-radius: 12px;
  color: #fff;
  background-color: #000;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.description {
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  div:not(:last-of-type) {
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 10px;
    opacity: 0.48;
  }

  p {
    color: #71727f;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
  }

  ul {
    color: #71727f;
    font-size: 14px;
    line-height: 18px;
    list-style: disc inside;
    margin-bottom: 10px;
  }
}
