:root {
  --color-white: #ffffff;
  --color-white-smoke: #f2f2f2;

  --color-black: #000000;
  --color-black-light: #333333;
  --color-matterhorn: #4c4c4c;

  --color-grey-default: #768fad;
  --color-grey-medium: #b6b7cb;
  --color-grey-light: #f3f6f9;

  --color-red: #f31c02;
  --color-red-strong: #bb0a30;

  --color-green-default: #98c11d;

  --color-blue-default: #003366;
  --color-blue-medium: #035793;
  --color-blue-light: #009ce8;

  --color-teal-default: #57c19c;

  --color-yellow-default: #ffe72c;
  --color-yellow-medium: #f1c35c;

  --primary-color: #98c11d;

  --color-box-shadow: rgba(0, 0, 0, 0.16);
  --color-box-shadow-line: #e1e2e8;

  --color-bg-disabled: var(--color-grey-light);

  // ----- theme variables ----

  --accent-color: #98c11d;

  --dark-text-color: #ffffff;
  --dark-link-active: #ffffff;

  --light-title-color: #333333;
  --light-text-color: #333333;
  --light-icon-color: #8798ad;
  --light-label-color: #8798ad;

  --input-textColor: #333333;
  --input-placeholderColor: #c2c5c7;
  --input-borderColor: #bfc5d2;
  --input-backgroundColor: #ffffff;

  --select-textColor: #333333;
  --select-placeholderColor: #333333;
  --select-borderColor: #f3f6f9;
  --select-backgroundColor: #ffffff;
  --select-disabled-backgroundColor: #f3f6f9;

  --layout-backgroundColor: #fafafa;
  --tabs-textColor: #8798ad;
  --calendarDay-accent-textColor: #f31c02;
  --menuMask-background: none;
  --dashboardCalendar-backgroundColor: rgba(255, 255, 255, 0.8);
  --statisticRow-color: #ffffff;
  --dashboardStatisticRow-color: #333333;
}

#root.aes {
  --accent-color: #bb0a30;

  --dark-text-color: #ffffff;
  --dark-link-active: #bb0a30;

  --light-title-color: #000000;
  --light-text-color: #000000;
  --light-icon-color: #000000;
  --light-label-color: #000000;

  --input-textColor: #333333;
  --input-placeholderColor: #c2c5c7;
  --input-borderColor: #f3f6f9;
  --input-backgroundColor: #ffffff;

  --select-textColor: #333333;
  --select-placeholderColor: #333333;
  --select-borderColor: #f3f6f9;
  --select-backgroundColor: #ffffff;
  --select-disabled-backgroundColor: #f3f6f9;

  --layout-backgroundColor: #f2f2f2;
  --tabs-textColor: #000000;
  --calendarDay-accent-textColor: #bb0a30;
  --menuMask-background: linear-gradient(
    to bottom,
    rgba(243, 246, 249, 1),
    rgba(255, 255, 255, 1)
  );
  --dashboardCalendar-backgroundColor: none;
  --statisticRow-color: #000000;
  --dashboardStatisticRow-color: #ffffff;
}

#root.gandh {
  --accent-color: #d2b464;
  --primary-color: #d2b464;
  --calendarDay-accent-textColor: var(--accent-color);
}
