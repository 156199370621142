@import '../../styles/shared';

.content {
  background: url('../../assets/images/bg-bubbles.svg') right center no-repeat,
    linear-gradient(to bottom, #a82837, #e2001a);

  padding: 40px 34px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include mazars {
    background: linear-gradient(0deg, #f3f6f9 0%, #ffffff 100%) 0 0 no-repeat;
  }

  @include aes {
    background: linear-gradient(to bottom, #f3f6f9, #ffffff);
  }

  @include gandh {
    background: var(--color-white);
  }
}

.header {
  margin-bottom: 36px;

  display: flex;
  align-items: center;

  color: var(--color-white);

  &__name {
    font-size: 21px;
    font-weight: bold;
  }

  &__icon {
    font-size: 48px;

    margin-right: 15px;
  }

  @include mazars {
    color: var(--color-blue-default);
  }

  @include aes {
    color: var(--color-black);
  }

  @include gandh {
    color: var(--color-black);
  }
}

.main {
  display: flex;
  flex-grow: 1;
}

.tabs_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  margin-right: 16px;
}

.tabs {
  display: flex;

  border-bottom: 1px solid rgba(#f3f6f9, 0.24);

  margin-bottom: 20px;

  @include mazars {
    border-bottom: 1px solid rgba(#003366, 0.24);
  }

  @include aes {
    border-bottom: 1px solid rgba(#000000, 0.24);
  }

  @include gandh {
    border-bottom: 1px solid rgba(#000000, 0.24);
  }
}

.tab {
  &__item {
    color: var(--color-white);
    font-size: 16px;
    font-weight: bold;

    padding: 0 10px 8px 10px;

    opacity: 0.6;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    @include mazars {
      color: var(--color-blue-default);
    }

    @include aes {
      color: var(--color-black);
    }

    @include gandh {
      color: var(--color-black);
    }
  }

  &__active {
    opacity: 1;
    box-shadow: inset 0 -4px 0 0 var(--color-white);

    @include aes {
      box-shadow: inset 0 -4px 0 0 var(--color-red-strong);
    }

    @include gandh {
      box-shadow: inset 0 -4px 0 0 var(--accent-color);
    }
  }

  &__value {
    margin-left: 8px;
  }
}

.invoices {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(227px, 1fr));
}

.tabs_section .invoice {
  word-break: break-word;
  overflow-wrap: break-word;

  width: auto;
}

.summary {
  width: 25%;

  padding: 40px 0;
}

.statisticWrapper:not(:last-of-type) {
  margin-bottom: 48px;

  @include gandh {
    position: relative;
  }
}

.statistic {
  color: var(--color-white);

  padding: 0;

  width: auto;
  min-height: auto;

  :global(.title) {
    margin-bottom: 20px;
  }

  :global(.row) {
    margin-bottom: 22px;
  }

  :global(.value) {
    font-size: 21px;
  }

  @include gandh {
    color: var(--color-black);

    & h3 {
      position: static;
      padding-left: 0;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--color-white);

  @include mazars {
    color: var(--color-blue-default);
  }

  @include aes {
    color: var(--color-black);
  }

  @include gandh {
    color: var(--color-black);
  }
}

.icon__arrow {
  @extend %align-center;

  width: 24px;
  height: 24px;

  font-size: 14px;
}
