@import '../../../styles/shared';

.content {
  display: flex;
  flex-grow: 1;

  margin-top: -32px;
}

.wrapper__description {
  width: 308px;

  margin-right: 16px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 64px;

  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 4px 8px #00000014;

  margin-bottom: 12px;
  padding: 12px 16px;
}

.controls__pdf {
  margin-right: auto;
}

.icon {
  cursor: pointer;

  color: #b6b7c4;
  font-size: 48px;
}

.btn__pdf {
  padding: 0;

  &:last-of-type {
    margin-right: 16px;
  }
}

.select__title {
  color: #333333;
  font-size: 16px;

  margin-bottom: 8px;
}

.select {
  :global(.select__single-value) {
    color: #333333;
  }

  :global(.select__indicators) {
    color: #333333;
  }

  :global(.select__control) {
    background-color: rgba(#e1e2e8, 0.24);
  }

  :global(.select__indicator-separator) {
    display: none;
  }

  :global(.select__indicator) {
    padding: 0 8px;
  }
}

.calendar {
  padding: 0 24px;

  :global(.react-calendar__navigation__arrow) {
    padding: 0;
    color: #8798ad;
  }

  :global(.react-calendar__navigation__label) {
    color: #2e384d;
  }

  :global(.react-calendar__navigation) {
    padding: 0;
    margin-bottom: 8px;
  }

  :global(.react-calendar__month-view__weekdays) {
    margin-bottom: 8px;
  }

  :global(.react-calendar__month-view__weekdays__weekday) {
    color: #b6c4ca;
    padding: 0;
  }

  :global(.react-calendar__month-view__days__day) {
    padding: 0;
    margin-bottom: 20px;
  }

  :global(.react-calendar__tile) {
    overflow: visible !important;
  }

  :global(.react-calendar__tile--now) {
    color: var(--color-red);

    @include aes {
      color: var(--color-red-strong);
    }
  }

  :global(.react-calendar__tile--active),
  :global(.react-calendar__tile--hasActive) {
    position: relative;

    color: var(--color-white);

    &::before {
      content: '';

      position: absolute;

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      width: auto;
      height: auto;

      transform: translate(0, 0);

      border-radius: 0;
      background: rgba(#f31c02, 0.5);

      @include mazars {
        background: rgba(#b6b7cb, 0.5);
      }

      @include aes {
        background: rgba(#bb0a30, 0.5);
      }

      @include gandh {
        background: rgba(#d2b464, 0.5);
      }
    }
  }

  :global(.react-calendar__tile--rangeStart),
  :global(.react-calendar__tile--rangeEnd) {
    position: relative;

    color: var(--color-white);

    @include aes {
      color: var(--color-white);
    }

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      width: 24px;
      height: 24px;

      border-radius: 50%;
      background: var(--color-red);

      @include mazars {
        background: var(--color-blue-default);
      }

      @include aes {
        background: var(--color-red-strong);
      }

      @include gandh {
        background: var(--accent-color);
      }
    }
  }

  :global(.react-calendar__tile--rangeStart:not([class*='rangeEnd'])::after),
  :global(.react-calendar__tile--rangeEnd:not([class*='rangeStart'])::after) {
    content: '';

    z-index: -1;

    position: absolute;
    top: 0;
    bottom: 0;

    width: 50%;

    background: rgba(red, 0.5);

    @include mazars {
      background: rgba(#b6b7cb, 0.5);
    }

    @include aes {
      background: rgba(#bb0a30, 0.5);
    }

    @include gandh {
      background: rgba(#d2b464, 0.5);
    }
  }

  :global(.react-calendar__tile--rangeStart) {
    &::after {
      left: 50%;
    }
  }

  :global(.react-calendar__tile--rangeEnd) {
    &::after {
      right: 50%;
    }
  }
}

.wrapper__select {
  margin-bottom: 15px;
}

.wrapper__select_last {
  margin-bottom: 33px;
}

.calculation {
  display: flex;
  align-items: center;

  color: #f3f6f9;
  font-size: 16px;

  background: linear-gradient(90deg, #a82837 0%, #e2001a 100%);
  border-radius: 4px;

  padding: 15px 16px;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  @include mazars {
    background: var(--color-grey-default);
  }

  @include aes {
    background: var(--color-red-strong);
  }

  @include gandh {
    background: var(--accent-color);
    & > span:last-child {
      font-size: 20px;
    }
  }
}

.calculation__title {
  margin-right: auto;
}

.wrapper__grid {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}

.grid {
  flex-grow: 1;

  :global(.grid-row) {
    display: grid;
    grid-template-columns: 40% 10% 10% 10% 10% 10% 10%;
  }
}
