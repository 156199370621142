@import '../../styles/shared';

.grid {
  overflow: hidden;

  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08%);

  :global {
    .header {
      position: sticky;
      top: 0;

      z-index: 1;
    }

    .item {
      position: relative;

      min-height: 77px;

      cursor: pointer;
      border-bottom: 1px solid #f3f6f9;

      &:hover {
        background-color: #f3f6f9;
      }

      &::before {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: 8px;
      }

      &__accepted::before,
      &__finished::before {
        background-color: #90d960;
      }

      &__inApproval::before {
        background-color: #f1c35c;
      }

      &__closed::before,
      &__declined::before {
        background-color: #fc2a42;
      }

      &__closed {
        background-color: #ffeef0;
        opacity: 0.6;
      }

      &__invoice {
        min-height: 72px;
      }

      &__report {
        min-height: 49px;
      }

      &__active {
        background-color: #e1e2e8;
      }
    }

    .column {
      display: flex;
      flex-direction: column;

      overflow: auto;

      padding: 16px;

      font-size: 14px;
      color: #313243;

      & p {
        padding-bottom: 5px;
      }
    }

    .caption {
      background-color: #f3f6f9;

      @include aes {
        background-color: var(--color-white-smoke);
      }

      color: #a6afbc;
      font-size: 12px;

      padding: 10px 16px;

      &__sortable {
        cursor: pointer;
      }

      &__active {
        display: flex;
        align-items: center;

        color: #676883;

        &::after {
          content: '';

          border: 4px solid transparent;

          margin-left: 12px;
        }

        &.type__asc::after {
          border-bottom-color: #676883;

          align-self: baseline;
        }

        &.type__desc::after {
          border-top-color: #676883;

          align-self: flex-end;
        }
      }
    }

    .name,
    .time {
      font-size: 12px;
      color: #a6afbc;
    }

    .id {
      font-weight: bold;
    }

    .title {
      width: fit-content;
    }

    .date {
      margin-right: 16px;
    }

    .wrapper {
      flex-wrap: wrap;
    }

    .icon {
      &__attr:not(:last-of-type) {
        margin-right: 18px;
      }
    }

    .label {
      position: relative;

      padding: 16px;

      &::after {
        content: attr(data-content);

        font-size: 12px;
        color: var(--color-white);

        margin-left: auto;
        padding: 4px 16px;

        border-radius: 2px;
        background-color: #90d960;
      }

      &__invoice::after {
        position: absolute;
        right: 16px;
        bottom: 9px;
      }
    }

    .justify__between {
      justify-content: space-between;
    }

    .expand__button {
      &__active {
        background-color: #f3f6f9;
        font-size: 12px;
        padding: 14px 20px 18px 12px;
        width: 0px;
        height: 0px;
        display: block;
        position: absolute;
        left: 15px;
        cursor: pointer;

        &::after {
          content: '';

          border: 4px solid transparent;
          border-top-color: #676883;
          display: block;
        }
      }

      &__inactive {
        background-color: #f3f6f9;
        font-size: 12px;
        padding: 12px 18px 20px 14px;
        width: 0px;
        height: 0px;
        display: block;
        position: absolute;
        left: 15px;
        cursor: pointer;

        &::after {
          content: '';

          border: 4px solid transparent;
          border-left-color: #676883;
          display: block;
        }
      }
    }

    .expandable_grid_row_header {
      .caption:nth-child(2) {
        margin-left: 49px;
      }
    }

    .body {
      .expand__button__active {
        margin-top: 8px;
        margin-left: 0;
        float: left;
      }

      .expand__button__inactive {
        margin-top: 8px;
        margin-left: 0;
        float: left;
      }

      .expandable_grid_column {
        margin-left: 34px;
      }
    }

    .panel {
      width: 60%;
    }
  }
}
