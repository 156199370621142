@import '../../styles/shared';

.page {
  display: grid;
  grid-template-columns: 364px 1fr;
  flex-grow: 1;
  overflow: auto;
}

.navigation {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}

.navigationRow {
  display: flex;
  flex-shrink: 0;
  height: 96px;
  padding: 24px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  @extend %scrollbar;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 96px;
  padding: 24px 34px 0 34px;
  font-size: 21px;
  font-weight: bold;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.header__title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--light-title-color);
}

.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(146px, 1fr));
  grid-column-gap: 18px;
  grid-row-gap: 16px;
  padding: 34px;
  min-width: 50%;
}

.order {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 34px;
  overflow: auto;
}

.orderInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.orderInfo__date {
  margin-bottom: 20px;
  font-size: 21px;
}

.orderInfo__item {
  margin-bottom: 22px;
  font-size: 14px;
}

.orderItem__icon {
  padding: 0 10px 0 6px;
}

.summary__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 40px 34px 24px 34px;
}

.summary__text {
  line-height: 24px;
  margin-bottom: 64px;
}

.summary__controls {
  display: flex;
  justify-content: space-between;
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 34px;
  box-shadow: 0 -1px 0 0 var(--color-box-shadow-line);
}

.summary__info {
  display: flex;
  flex-direction: column;
}

.summary__title {
  margin-bottom: 2px;
  font-size: 12px;
  color: var(--color-grey-medium);
}

.summary__total {
  font-size: 18px;
  font-weight: bold;
}

.order__rooms {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 0;
  @extend %scrollbar;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 6px;
  }
}

.room__controls {
  display: flex;
}

.btn__roomSetup {
  width: 60px;
  height: 60px;
  padding: 18px;
  color: var(--color-grey-default);
  border-radius: 4px;
  cursor: pointer;

  &.active {
    color: #fff;
    background-color: var(--color-green-default);

    @include aes {
      background-color: var(--color-red-strong);
    }

    @include gandh {
      background-color: var(--accent-color);
    }
  }
}

.summary__data {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.summary__main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 40px 50px;
  background-color: #fff;
  box-shadow: -1px 0 0 0 var(--color-box-shadow-line);
  overflow: auto;
  @extend %scrollbar;
}

.totalTitle {
  margin-left: auto;
  margin-right: -24px;
  margin-bottom: 20px;
}

.data__line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.data__icon {
  margin-right: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.rules {
  margin-bottom: 40px;

  @include gandh {
    display: none;
  }
}

.rule:not(:last-child) {
  margin-bottom: 24px;
}

.checkboxLink {
  color: #8798ad;
  text-decoration: underline !important;
}
