$mobile: 440px;
$tablet: 1024px;
$laptop: 1366px;
$desktop: 1920px;

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (max-width: $tablet) {
      @content;
    }
  } @else if $media == laptop {
    @media only screen and (max-width: $laptop) {
      @content;
    }
  }
}

%scrollbar {
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: mix(#4c4c4c, #fff, 30%);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: mix(#4c4c4c, #fff, 50%);
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: mix(#4c4c4c, #fff, 70%);
  }
}

%align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin mazars {
  @at-root :global(html .mazars) & {
    @content;
  }
}

@mixin sionum {
  @at-root :global(html .sionum) & {
    @content;
  }
}

@mixin aes {
  @at-root :global(html .aes) & {
    @content;
  }
}

@mixin gandh {
  @at-root :global(html .gandh) & {
    @content;
  }
}
