@import '../../../../styles/shared';

.select {
  color: #71727f;
  border-radius: 4px;
  border: 1px solid #bfc5d2;

  :global(.select__control) {
    height: 32px;

    background-color: var(--select-backgroundColor);
    border: 1px solid var(--select-borderColor);

    &:hover,
    &:focus {
      border: 1px solid var(--select-borderColor);
    }
  }

  :global(.select__control--is-focused) {
    box-shadow: none !important;
  }

  :global(.select__control--is-disabled) {
    background-color: var(--select-backgroundColor);
  }

  :global(.select__single-value) {
    font-size: 16px;
    color: var(--select-textColor);
  }

  :global(.select__placeholder) {
    font-size: 16px;
    color: var(--select-placeholderColor);
    opacity: 0.5;
  }

  :global(.select__indicator-separator) {
    background-color: transparent;
  }

  :global(.select__input) {
    color: var(--select-textColor);
  }

  :global(.select__option) {
    background-color: transparent !important;
  }

  :global(.select__option--is-selected) {
    color: var(--select-textColor);
    background-color: var(--color-white) !important;
  }

  :global(.select__option--is-focused) {
    background-color: var(--color-white) !important;
  }

  :global(.select__single-value) {
    overflow: visible;
  }

  :global(.select__indicators) {
    margin: 4px;
    font-size: 24px;
    color: var(--light-icon-color);
  }
}
