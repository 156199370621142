@import '../../styles/shared';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  height: 60px;

  background-color: var(--color-red);

  @include mazars {
    background-color: var(--color-blue-default);
  }

  @include aes {
    background-color: var(--color-black);
  }

  @include gandh {
    background-color: var(--accent-color);
  }
}

.logo {
  height: 100%;
  padding: 10px 8px;
  background-color: var(--color-white);
  display: none;

  @include aes {
    display: block;
  }

  @include gandh {
    width: 60px;
    height: 60px;
    display: block;
  }
}

.linkWrapper {
  display: flex;
  align-self: stretch;
}

.contentWrapper {
  @extend %align-center;

  color: var(--color-white);

  padding: 14px 0;
  align-self: stretch;
}

.link {
  @extend %align-center;
  font-size: 24px;
  color: var(--color-white);

  width: 60px;

  background-color: var(--color-red);

  @include mazars {
    background-color: var(--color-blue-default);
  }

  @include aes {
    background-color: var(--color-black);
  }

  @include gandh {
    background-color: var(--accent-color);
  }
}

.link__active {
  color: var(--color-red);
  background-color: var(--color-white);

  @include mazars {
    color: var(--color-blue-default);
    background-color: var(--color-white);
  }

  @include aes {
    background-color: var(--color-red-strong);
    color: var(--color-white);
  }

  @include gandh {
    color: var(--accent-color);
    background-color: var(--color-white);
  }
}

.iconUsers {
  @extend %align-center;

  svg {
    width: 20px;
    height: 20px;
  }
}
