.content {
  display: flex;
  align-items: center;

  padding: 0 16px;
}

.time,
.date {
  padding: 0 6px;
}

.time {
  border-left: 1px solid var(--color-white);
}
