@import '../../../styles/shared';

.navigation {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.navigationRow {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 96px;
  padding: 24px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.onlyAvailableLabel {
  display: flex;
  justify-content: space-between;
  color: var(--light-text-color);

  & > span {
    margin-right: 1em;
  }
}

.page {
  display: grid;
  grid-template-columns: 364px 1fr;
  flex-grow: 1;
}

.filterSection {
  flex-grow: 1;
  padding: 24px 34px;
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;

  & .roomSearch {
    border: 0;
    padding-left: 0;

    & i {
      margin: 0 3px 0 -3px;
      position: static;
    }

    & input {
      border: 1px solid var(--input-borderColor);
      height: 38px;
      border-radius: 4px;
      padding: 0 8px;
      width: 218px;
    }

    & button {
      padding: 0 11px;
      width: 12px;
      margin-left: 16px;
    }
  }
}

.filterControlsLine {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.selectUserMessage {
  margin-bottom: 24px;
  line-height: 1.2;
  color: var(--light-text-color);
}

.filterDate {
  align-items: flex-start;
}

.filterWrapper .filterControlsLine .calendar {
  --font-size-base: 7px;

  :global(.react-calendar__tile) {
    padding: 10px 0;
  }

  :global(.react-calendar__tile--now) {
    color: var(--calendarDay-accent-textColor);

    &:before {
      background: transparent;
    }
  }

  :global(.react-calendar__tile--active) {
    position: relative;
    color: var(--color-white);

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;

      width: calc(var(--font-size-base) * 3.4);
      height: calc(var(--font-size-base) * 3.4);

      transform: translate(-50%, -50%);
      border-radius: 50%;

      background-color: var(--calendarDay-accent-textColor);
    }
  }
}

.icon {
  color: var(--color-black-light);
  margin-right: 8px;
}

input.field__bookingFilter {
  height: 38px;
  font-size: 16px;
  color: var(--input-textColor);
  background-color: var(--input-backgroundColor);
  border: 1px solid var(--input-borderColor);
}

.select {
  width: 100%;

  &:not(:last-child) {
    margin-right: 24px;
  }
}

.summary__wrapper {
  transform: translateY(364px);
  transition: transform 0.3s ease;

  &.visible {
    transform: translateY(0);
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 34px;
  box-shadow: 0 -1px 0 0 var(--color-box-shadow-line);
}

.summary__info {
  display: flex;
  flex-direction: column;
}

.summary__title {
  margin-bottom: 2px;
  font-size: 12px;
  color: var(--color-grey-medium);
}

.summary__total {
  font-size: 18px;
  font-weight: bold;
}

.roomSearch {
  border: 0;
}
