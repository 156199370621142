@import '../../styles/shared';

@mixin btn-style($bg-hover, $bg-active) {
  &:hover {
    background-color: $bg-hover;
  }

  &:active {
    background-color: $bg-active;
  }
}

.btn {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 4px;

  font-size: 14px;
  color: inherit;

  height: 40px;
  padding: 0 24px;

  transition: 0.15s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.24;
  }

  @include aes {
    border-radius: 0;
  }
}

.btn__ghost {
  @include btn-style(#e1e2e8, #b6b7cb);
  background-color: #f3f6f9;

  color: #71727f;

  &.color {
    @include btn-style(rgba(#e1e2e8, 0.48), rgba(#b6b7cb, 0.16));
    background-color: rgba(#fff, 0.24);

    color: var(--color-white);
  }

  @include aes {
    color: var(--color-black);
    background-color: transparent;
    border: 1px solid var(--color-black);
  }
}

.btn__reject {
  @include btn-style(#f31c02, #c6081e);
  background-color: #e2001a;

  color: var(--color-white);

  &.color {
    @include btn-style(rgba(#fff, 0.48), rgba(#fff, 0.16));

    background-color: rgba(#fff, 0.24);
  }

  @include aes {
    background-color: var(--color-red-strong);
  }

  @include gandh {
    background-color: var(--color-white);
    color: var(--color-black);
    border: var(--color-black) 1px solid;
  }
}

.btn__accept {
  @include btn-style(#b3de1c, #7fa028);
  background-color: var(--primary-color);

  color: var(--color-white);

  @include aes {
    background-color: var(--color-black);
  }

  @include gandh {
    background-color: #d2b464;
    @include btn-style(#edd163, #b99359);
  }
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.btn__text {
  line-height: 0;
}
