.Toastify .notifications {
  padding: 0;

  min-width: 300px;
}

.Toastify .notification {
  padding: 0;
  min-height: auto;

  overflow: auto;

  background-color: transparent;
}

.Toastify__close-button {
  position: absolute;
  top: 12px;
  right: 16px;

  font-size: 16px;

  color: var(--color-white);
}
