@import '../../styles/shared';

.layout {
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-wrap: wrap;

  position: relative;

  @include gandh {
    background: url('../../assets/images/app_bild.jpg') left top no-repeat;
    background-size: 100% 100%;
  }
}

.logo {
  @include respond-to(tablet) {
    @include sionum {
      :global(svg) {
        width: 100px;
      }
    }
  }
}

.wrapper__side {
  @extend %align-center;

  width: 50%;
  padding: 0 15px;

  @include respond-to(tablet) {
    width: 100%;
    padding: 15px 40px;
  }
}

.wrapper__left {
  background: url('../../assets/images/bg-login.svg') center center no-repeat,
    linear-gradient(180deg, #a82837, #e2001a) no-repeat;

  @include mazars {
    background: var(--color-blue-default);
  }

  @include aes {
    background: var(--color-red-strong);
  }

  @include respond-to(mobile) {
    background: var(--color-white);
  }

  @include gandh {
    background: none;
  }
}

.wrapper__form {
  position: relative;
  @extend %align-center;
  flex-direction: column;

  width: 532px;
  min-height: 608px;

  border-radius: 15px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.16);
  background: linear-gradient(0deg, #f3f6f9, var(--color-white));

  @include respond-to(mobile) {
    background: var(--color-white);
    box-shadow: none;

    width: 100%;
    min-height: auto;
    padding: 15px;
  }
}

.title {
  color: #69707f;
  font-size: 24px;
  text-align: center;

  margin-bottom: 48px;
}

.form {
  width: 308px;
}

.wrapper__btn {
  text-align: right;
}

.btn {
  @include respond-to(mobile) {
    width: 100%;
  }
}

.controls__form {
  margin-bottom: 42px;
}

.control:not(:last-of-type) {
  margin-bottom: 32px;
}

.message {
  margin-bottom: 24px;

  border: 1px solid #ffa39e;
  border-radius: 4px;
  background-color: var(--color-white);

  padding: 8px 15px;

  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.recover__successMessage {
  width: 308px;
  line-height: 18px;
  color: var(--light-text-color);
}

.recover__text {
  margin-bottom: 24px;
  line-height: 22px;
  color: var(--light-text-color);
}

.link__wrapper {
  position: absolute;
  bottom: 56px;
  display: flex;
  font-size: 14px;
}

.link__note {
  margin-right: 8px;
  color: var(--light-text-color);
}

.link {
  color: var(--accent-color);
}
